

export default class ModalHelper { 

	static shell() { 
		return { 
				loading: false,
				show: false,
				error: false, 
				message: null
			}
	}

	showLoading(state) { 
		state.setState({system: {loading: true, show: true, erorr: false, message: null, onClick: null}})
	}

	showError(state, errorMessage, callback=null) { 
		state.setState({system: {loading: false, show: true, error: true, message: errorMessage, onClick: callback}})
	}

	hideModal(state) {
		state.setState({system: {loading:false, show: false, error: false, message:null, onClick: callback}})
	}

	hideModalAndClearAll(state) { 
		state.setState({system: {loading: false, show: false, erorr: false, message: null, onClick: null}});
	}

	showSuccess(state, sucessMessage, callback) { 
		state.setState({system:{loading: false, show: true, error: false, message: sucessMessage, onClick:callback}})
	}

	showModal(state) { 
		state.setState({system: {loading: false, show: true, erorr: false, message: null, onClick: null}})
	}

	hideModal(state) { 
		state.setState({system: {loading: false, show: false, erorr: false, message: null, onClick: null}})
	}

}