import APIServer from "./APIServer";


export default class WaitlistAPI extends APIServer { 
    
    async addToWaitlist(waitlistId, body) { 
        return await super.post(`/waitlist/${waitlistId}/add`, body)
    }

    async consumeSeatId(seatId, body) { 
        return await super.post(`/waitlist/${seatId}/consume`, body)
    }
}